* {
  box-sizing: border-box;
}

body {
  text-align: center;
  background-color: #eeeeee;
}

.Row {
  display: flex;
  justify-content: center;
}

.Row-letter {
  margin: 2px;
  border: 2px solid rgba(0, 0, 0, 0.4);
  width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.App-container {
  position: relative;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.App-container h1 {
  margin-top: 0;
}

.Game {
  display: flex;
  flex-direction: column;
  user-select: none;
}

.Game-keyboard {
  display: flex;
  flex-direction: column;
}

.Game-keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.Game-keyboard-button {
  margin: 2px;
  background-color: #cdcdcd;
  padding: 2px;
  text-transform: capitalize;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: inherit;
  text-decoration: inherit;
  border: 2px solid transparent;
  cursor: pointer;
}

.Game-keyboard-button-wide {
  flex: 2;
}

.Game-keyboard-button:focus {
  outline: none;
}

.letter-correct {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgb(87, 172, 87);
  color: white;
}

.letter-elsewhere {
  border: 2px dotted rgba(0, 0, 0, 0.3);
  background-color: #e9c601;
  color: white;
}

.letter-absent {
  border: 2px solid transparent;
  background-color: rgb(162, 162, 162);
  color: white;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #404040;
    color: #e0e0e0;
  }

  .Game-keyboard-button {
    color: #404040;
  }

  .Row-letter {
    border: 2px solid rgba(255, 255, 255, 0.4);
  }

  .letter-correct {
    border: 2px solid rgba(0, 0, 0, 0.3);
    color: white;
  }

  .letter-elsewhere {
    border: 2px dotted rgba(0, 0, 0, 0.3);
    color: white;
  }

  .letter-absent {
    border: 2px solid transparent;
    background-color: rgb(142, 142, 142);
    color: white;
  }
}

a,
a:visited {
  color: #8080ff;
}

a:active {
  color: #cc77ff;
}

.Game-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.Game-options > * + * {
  margin-inline-start: 0.5rem;
}

.Game-options button {
  min-width: 4rem;
}

.App-footer {
  margin: -1rem 0 2rem 0;
  font-size: 80%;
  line-height: 1.5;
}

.App-about {
  margin-top: -1rem;
  line-height: 1.4;
}

.Game-seed-info {
  opacity: 0.5;
  margin-top: 1em;
  font-variant-numeric: tabular-nums;
}
